<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">开班统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl" :style="dialogVisible == true
            ? 'border-bottom: 1px solid #f5f5f6'
            : 'border-bottom:none'
          ">
          <div class="searchbox">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">班级编码:</span>
              <el-input v-model="projectCode" type="text" size="small" clearable placeholder="请输入班级编码" />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input v-model="projectName" type="text" size="small" clearable placeholder="请输入班级名称"
                style="flex: 1" />
            </div>
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-select size="small" v-model="ruleForm.unitCompId" remote :remote-method="getuserCompanyList"
                filterable clearable placeholder="请至少输入两个字搜索" style="width: 100%">
                <el-option v-for="item in userCompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div>
              <!-- <el-button class="bgc-bv" round @click="getData()" size="small">查询</el-button> -->
              <transition name="el-zoom-in-center">
                <el-button type="primary" class="bgc-bv" round @click="getData()" v-show="dialogVisible">查询</el-button>
              </transition>
            </div>
            <div style="margin-left: 10px">
              <el-button type="text" round @click="search()" size="small">
                高级搜索
                <i v-if="dialogVisible" class="el-icon-bottom"></i>
                <i v-else class="el-icon-top"></i>
              </el-button>
            </div>
          </div>
          <div class="btnBox">
            <el-button class="bgc-bv" round @click="doExport()">导出</el-button>
            <el-button class="bgc-bv" round @click="open" size="small">自定义表头</el-button>
          </div>
        </div>
        <el-collapse-transition>
          <div v-show="!dialogVisible">
            <div class="operationControl" style="border-bottom: none">
              <div class="searchbox">
                <div title="负责人电话" class="searchboxItem ci-full">
                  <span class="itemLabel projectUser">负责人电话:</span>
                  <el-input v-model="ruleForm.projectUserPhone" type="text" size="small" placeholder="请输入负责人电话"
                    clearable />
                </div>

                <div title="培训状态" class="searchboxItem ci-full">
                  <span class="itemLabel">培训状态:</span>
                  <el-select v-model="ruleForm.studystate" placeholder="请选择培训状态" size="small" clearable>
                    <el-option v-for="item in studylist" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </div>
                <div title="培训类型" class="searchboxItem ci-full">
                  <span class="itemLabel">培训类型:</span>
                  <tree ref="tree" @eventBtn="childBack" :ruleForm="ruleForm" size="small" :modal="false" typeStu
                    @clearParams="clearParams" />
                </div>
                <div title="补贴班级" class="searchboxItem ci-full">
                  <span class="itemLabel">补贴班级:</span>
                  <el-select v-model="ruleForm.isSubsidy" placeholder="请选择是否补贴班级" size="small" clearable>
                    <el-option v-for="item in SubsidyList" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="operationControl">
              <div class="searchbox">
                <div title="行政区划" class="searchboxItem">
                  <span class="itemLabel" style="min-width: 6rem">行政区划:</span>
                  <el-cascader clearable filterable v-model="ruleForm.areaId" :options="areaTreeList" :props="propsArea"
                    size="small"></el-cascader>
                </div>
                <div title="开班时间" class="searchboxItem">
                  <span class="itemLabel">开班时间 :</span>
                  <el-date-picker v-model="ruleForm.date" size="small" type="daterange" style="width: 5rem"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" />
                </div>
                <div title="结束时间" class="searchboxItem">
                  <span class="itemLabel" style="min-width: 6rem">结束时间:</span>
                  <el-date-picker v-model="ruleForm.dateend" size="small" type="daterange" style="width: 5rem"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" />
                </div>
                <div class="df">
                  <el-button type="primary" class="bgc-bv" round @click="getsearch">查询</el-button>
                </div>
                <div class="df" style="margin-left: 10px">
                  <el-button type="text" round @click="resetForm">清空搜索条件</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>

        <div class="framePage-scroll">
          <div class="ovy-a">
            <div style="margin-bottom: 15px">
              <span>合计开班数：{{ totalProjectNum }}</span>
              <span style="margin: 0 15px">培训结束班级数：{{ endProjectNum }}</span>
              <span>培训中班级数：{{ totalProjectNum - endProjectNum }}</span>
            </div>
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe v-if="showtable">
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="班级编码" fixed align="left" show-overflow-tooltip prop="projectCode"
                min-width="200" />
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="200" />
              <el-table-column v-for="(el, index) in tableHaed" :key="index" :label="el.label" :align="el.textAlign"
                show-overflow-tooltip :min-width="el.width">
                <template slot-scope="scope">
                  {{ getValue(el, scope.row) }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>

      <el-dialog title="自定义表头" :visible.sync="tableHeadDialog" width="30%" class="custom" @close="cencal">
        <p style="padding-left: 20px; margin-bottom: 10px">
          勾选需要显示在列表上的项，可拖动调整顺序
        </p>
        <div>
          <el-tree ref="headTree" :data="treeDataCopy" default-expand-all draggable :allow-drop="allowDrop"
            :allow-drag="allowDrag">
            <template slot-scope="{ node, data }">
              <el-checkbox @change="(val) => {
                  handleCheckChange(val, data);
                }
                " v-model="data.checked" :disabled="data.label == '课程名称'">{{ node.label }}</el-checkbox>
            </template>
          </el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cencal">取 消</el-button>
          <el-button class="bgc-bv" @click="resetHeadOk">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "set/openingStatistics",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      userCompanyList: [],
      showtable: false,
      dialogVisible: true,
      projectCode: "",
      projectName: "",

      studylist: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "50",
          label: "培训中",
        },
        {
          value: "60",
          label: "培训结束",
        },
      ],
      SubsidyList: [

        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      totalProjectNum: 0,
      endProjectNum: 0,
      ruleForm: {
        studystate: "",
        Trainingtype: "",
        date: [],
        dateend: [],
        unitCompId: "",
        areaId: "",
        isSubsidy: '', //是否补贴班级
      },
      params: {},
      tableHeadDialog: false, //自定义表头弹窗
      fixedHeader: [
        {
          label: "班级编码", //表头名称
          propName: "projectCode", //后台对应字段名称
          textAlign: "center", //文字对齐方式
          renderType: "selfJudgment", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应keys
          width: "60", // 列的宽
          order: -1,
          id: "1",
          checked: true,
        },
        {
          label: "班级名称", //表头名称
          propName: "projectName", //后台对应字段名称
          textAlign: "left", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 0,
          id: "2",
          checked: true,
        }
      ],
      treeData: [
        {
          label: "培训类型", //表头名称
          propName: "trainType", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "140", // 列的宽
          order: 1,
          id: "1",
          checked: true,
        },
        {
          label: "开班时间", //表头名称
          propName: "startDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 2,
          id: "2",
          checked: true,
        },
        {
          label: "结束时间", //表头名称
          propName: "endDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 3,
          id: "3",
          checked: true,
        },
        // {
        //   label: "计划培训人数", //表头名称
        //   propName: "projectPeople", //后台对应字段名称
        //   textAlign: "right", //文字对齐方式e
        //   renderType: "nummer", //渲染类型
        //   timeType: "", //时间字段的渲染类型
        //   dictionaries: "", //字典对应key
        //   width: "100", // 列的宽
        //   order: 4,
        //   id: "4",
        //   checked: true,
        // },
        {
          label: "学员总数", //表头名称
          propName: "userTotalCount", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 5,
          id: "5",
          checked: true,
        },
        {
          label: "实名认证人数", //表头名称
          propName: "userAuthCount", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 6,
          id: "19",
          checked: true,
        },
        {
          label: "实名认证率", //表头名称
          propName: "userAuthProbability", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 7,
          id: "20",
          checked: true,
        },
        {
          label: "学习人数", //表头名称
          propName: "userLearnCount", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "80", // 列的宽
          order: 8,
          id: "21",
          checked: true,
        },
        {
          label: "学习率", //表头名称
          propName: "userLearnProbability", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 9,
          id: "22",
          checked: true,
        },
        {
          label: "学习完成人数", //表头名称
          propName: "userCompleteCount", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 10,
          id: "6",
          checked: true,
        },
        {
          label: "完成率", //表头名称
          propName: "userCompleteProbability", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 12,
          id: "24",
          checked: true,
        },
        {
          label: "结业人数", //表头名称
          propName: "projectCompleteCount", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 11,
          id: "23",
          checked: true,
        },
        {
          label: "未结业人数", //表头名称
          propName: "projectUnCompleteCount", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "80", // 列的宽
          order: 13,
          id: "25",
          checked: true,
        },
        {
          label: "培训状态", //表头名称
          propName: "trainState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "PROJECTSTATE", //字典对应key
          width: "120", // 列的宽
          order: 14,
          id: "7",
          checked: true,
        },
        {
          label: "负责人", //表头名称
          propName: "projectUser", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 15,
          id: "8",
          checked: true,
        },
        {
          label: "负责人电话", //表头名称
          propName: "projectUserPhone", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 16,
          id: "9",
          checked: true,
        },
        {
          label: '课件总数', //表头名称
          propName: "kpointTotalNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 17,
          id: "10",
          checked: true,
        },
        {
          label: "岗位类型", //表头名称
          propName: "post", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 18,
          id: "11",
          checked: true,
        },
        {
          label: "行业类型", //表头名称
          propName: "industry", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 19,
          id: "12",
          checked: true,
        },

        {
          label: "职业/工种", //表头名称
          propName: "occupation", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 20,
          id: "13",
          checked: true,
        },
        {
          label: "培训等级", //表头名称
          propName: "trainLevel", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 21,
          id: "14",
          checked: true,
        },
        {
          label: "单位名称", //表头名称
          propName: "compName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "190", // 列的宽
          order: 22,
          id: "15",
          checked: true,
        },
        {
          label: "行政区划", //表头名称
          propName: "areaNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "180", // 列的宽
          order: 24,
          id: "17",
          checked: true,
        },
        {
          label: "是否补贴班级", //表头名称
          propName: "isSubsidy", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "selfJudgment", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "180", // 列的宽
          order: 25,
          id: "18",
          checked: true,
        },
      ],
      tableHaed: [],
      treeDataCopy: [],
      areaTreeList: [], //行政区划
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {
    this.getTableHeight();
    this.getTableHead();
    this.getAreaTree();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() { },
  methods: {
    init() {
      this.getData();
    },
    /* 单位名称 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyFJList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    // 导出
    doExport() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            projectCode: this.projectCode,
            projectName: this.projectName || null,
            isSubsidy: this.ruleForm.isSubsidy,
          };
          if (this.ruleForm.projectUserPhone) {
            params.projectUserPhone = this.ruleForm.projectUserPhone;
          }
          if (this.ruleForm.date) {
            params.startDateBegin = this.ruleForm.date[0];
            params.startDateEnd = this.ruleForm.date[1];
          }
          if (this.ruleForm.areaId) {
            params.areaId = this.ruleForm.areaId;
          }
          if (this.ruleForm.dateend) {
            params.endDateBegin = this.ruleForm.dateend[0];
            params.endDateEnd = this.ruleForm.dateend[1];
          }
          if (this.ruleForm.studystate) {
            params.trainState = this.ruleForm.studystate;
          }
          if (this.ruleForm.unitCompId) {
            params.compNameId = this.ruleForm.unitCompId;
          }
          if (this.params.trainFormId) {
            params.trainTypeId = this.params.trainFormId;
          }
          if (this.params.levelFormId) {
            params.trainLevelId = this.params.levelFormId;
          }
          if (this.params.occFormId) {
            params.occupationId = this.params.occFormId;
          }
          if (this.params.postFormId) {
            params.postId = this.params.postFormId;
          }
          if (this.params.industryFormId) {
            params.industryId = this.params.industryFormId;
          }
          params.treeData = this.fixedHeader.concat(this.treeData);
          this.$post("/biz/report/project/async/export", params)
            .then((res) => {
              console.log(res.status);
              if (res.status == "0") {
                let list = [];
                list.push(res.data);
                for (let item of list) {
                  console.log(item);
                  if (!this.downloadItems.includes(item.taskId)) {
                    this.$store.dispatch("pushDownloadItems", item.taskId);
                  } else {
                    this.$message.warning(
                      "[" + item.fileName + "]已经申请下载,请耐心等待"
                    );
                  }
                }
              } else {
                this.$message.error(re.message);
              }
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => { });
    },
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    //获取行政区划
    getAreaTree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areaTreeList = ret.data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        isSubsidy: this.ruleForm.isSubsidy,
      };
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.ruleForm.projectUserPhone) {
        params.projectUserPhone = this.ruleForm.projectUserPhone;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.date) {
        params.startDateBegin = this.ruleForm.date[0];
        params.startDateEnd = this.ruleForm.date[1];
      }
      if (this.ruleForm.dateend) {
        params.endDateBegin = this.ruleForm.dateend[0];
        params.endDateEnd = this.ruleForm.dateend[1];
      }
      if (this.ruleForm.studystate) {
        params.trainState = this.ruleForm.studystate;
      }
      if (this.ruleForm.unitCompId) {
        params.compNameId = this.ruleForm.unitCompId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/report/project/page-list",
        params,
        pageNum,
      });
      this.getcount();
    },
    getcount() {
      let params = {
        isSubsidy: this.ruleForm.isSubsidy,
      };
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.ruleForm.projectUserPhone) {
        params.projectUserPhone = this.ruleForm.projectUserPhone;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.date) {
        params.startDateBegin = this.ruleForm.date[0];
        params.startDateEnd = this.ruleForm.date[1];
      }
      if (this.ruleForm.dateend) {
        params.endDateBegin = this.ruleForm.dateend[0];
        params.endDateEnd = this.ruleForm.dateend[1];
      }
      if (this.ruleForm.studystate) {
        params.trainState = this.ruleForm.studystate;
      }
      if (this.ruleForm.unitCompId) {
        params.compNameId = this.ruleForm.unitCompId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.$post("/biz/report/project/summarizing", params)
        .then((ret) => {
          const res = ret.data;
          this.totalProjectNum = res.totalProjectNum;
          this.endProjectNum = res.endProjectNum;
        })
        .catch((err) => {
          return;
        });
    },
    getsearch() {
      this.dialogVisible = false;
      this.getData();
    },
    // 高级搜索弹窗
    search() {
      this.dialogVisible = !this.dialogVisible;
      this.getTableHeight();
    },
    //重置
    //清空搜索条件
    resetForm() {
      // this.$refs[formName].resetFields();
      this.params = {};
      this.ruleForm = {};
      // this.dialogVisible = true;
      this.projectCode = "";
      this.projectName = "";
      // this.getTableHeight();
    },
    //取消
    cancleForm(formName) {
      this.dialogVisible = false;
      this.params = {};
      this.resetForm("ruleForm");
    },
    doClose() {
      this.dialogVisible = false;
      this.$refs.tree.clear(); // 高级搜索关闭，清空培训类型数据
      this.ruleForm = {
        studystate: "",
        Trainingtype: "",
        date: "",
      };
      this.params = {};
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.dialogVisible == true
            ? 40 + 0.675 * 16 + 1 + 25
            : 80 + 40 + 0.675 * 16 + 1 + 25;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      if (
        draggingNode.label == "班级编码" ||
        draggingNode.label == "班级名称"
      ) {
        return false;
      }
      return true;
    },
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        return this.$setDictionary(el.dictionaries, row[el.propName]);
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss")
            : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else if (el.renderType == "nummer") {
        return row[el.propName] || "0";
      } else if (el.renderType == "selfJudgment") {
        //没有字典根据返回自己判断是否
        // 是否评价
        if (el.label == "是否补贴班级") {
          if (row[el.propName] == true) {
            return "是";
          } else {
            return "否";
          }
        }
      } else {
        // 其他
        return row[el.propName] || "--";
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHaed = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "openingstatistics",
      }).then((res) => {
        this.getTableHead();
      });
    },
    //自定义表头取消
    open() {
      this.tableHeadDialog = true;
      this.handle();
    },
    cencal() {
      this.tableHeadDialog = false;
    },
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "openingstatistics",
      })
        .then((res) => {
          if (res.status == 0) {
            const data = res.data || {};
            let list = [];
            if (data.configInfo && data.configInfo != "") {
              list = JSON.parse(data.configInfo);
            }
            if (list.length) {
              list.map((el) => {
                this.treeData.forEach((it, index) => {
                  if (el.id == it.id) {
                    it.checked = el.checked;
                    it.order = el.order;
                  }
                });
              });
              this.treeData.sort((a, b) => {
                return a.order - b.order;
              });
              this.handle();
            } else {
              // this.tableHaed = [...this.treeData];
              this.handle();
            }
          } else {
            // this.tableHaed = [...this.treeData];
            this.handle();
          }
        })
        .catch((err) => {
          this.tableHaed = [...this.treeData];
        });
    },
    handle() {
      this.tableHaed = [];
      this.treeDataCopy = [];
      this.treeData.forEach((el) => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      this.treeDataCopy.forEach((el) => {
        if (el.checked) {
          this.tableHaed.push(el);
        }
      });
      this.showtable = true;
    },
    handleCheckChange(val, data) { },

  },
};
</script>

<style lang="less" scope>
.projectUser {
  min-width: 6rem !important;
}

.custom {
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #5c6be8;
    border-color: #5c6be8;
  }

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #5c6be8;
  }
}
</style>
